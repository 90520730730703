.pod {
    padding: 10px;
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;

    .pod-container{
        flex: 1 0 auto;
        position: relative;
        min-height: 100%;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.3);
        background-color: #fff;

        .pod-container-titlebar{
            min-height: 40px;
            font-size: 18px;
            padding: 5px 0;
            // margin-right: 15px;
            margin-left: 15px;
            height: auto;
            overflow: hidden;
            
            .titlebar-title{
                padding-top: 3px;
                padding-right: 0;
                max-width: 75%;
                float: left;
                font-weight: bold;
            }

            .titlebar-buttons{
                float: right !important;
                text-align: right;
                padding-left: 4px;
                padding-right: 4px;
                max-width: 25%;

                .miso-icon{
                    color: #7F8F90;
                    margin-left: 7px;
                    font-size: 25px;
                    cursor: pointer;
                }
            }
        }

        .chart-container{
            .interval{
                width: 100%;
                margin-bottom: 1rem;
                font-weight: bold;
            }
            .chart-area{
                border-top: 1px solid #CDD7D8;
                padding: 15px;
            }
        }
    }
}

.download-modal {
    display:flex;
    
    .download-btn {
        margin: 3px;
    }
}
