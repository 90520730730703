@import '~bootstrap/scss/bootstrap';

:root {
  --miso-light-blue: #54c0e8;
  --miso-light-yellow: #e0df00;
  --miso-light-red: #e74f3d;
  --miso-light-orange: #ffc844;
  --miso-light-teal: #b6dce1;
  --miso-light-gray: #d9d8d6;
  --miso-blue: #0082ca;
  --miso-green: #80bc00;
  --miso-red: #b2292e;
  --miso-teal: #91b6bb;
  --miso-gray: #a7a8a9;
  --miso-dark-blue: #004d71;
  --miso-dark-green: #487629;
  --miso-dark-red: #691c32;
  --miso-dark-orange: #ee7623;
  --miso-dark-teal: #4b858e;
  --miso-dark-gray: #636569;
  --miso-muted-blue: #d9edf7; /* Not in MISO Brand book, but consistent with www.misoenergy.org/ */
  --miso-selected-border: #86b7fe;
}


.home{
  padding: 10px 10px 10px 10px;

  .banner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0 10px 0;
    padding: 10px 0 10px 0;
    background-color: #F9F9F9;
    border: 2px solid var(--miso-green);
    // border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    text-align: center;

    .banner-title{
      font-size: 24px;
      font-weight: bold;
      margin: 10px 0 10px 0;
    }

    .banner-subtitle{
      font-size: 18px;
      font-weight: normal;
      margin: 10px 0 10px 0;
    }
  }

  .bottom {
    display:flex;
    align-items: flex-end;
  }

}

.podContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.chart-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column-2 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 45%;
}

@media screen and (min-width: 1000px) {
  .column-2 {
    flex: 1
  }
}

.miso-icon{
  font-family: miso_icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  font-size: 1em;
  width: auto;
  height: auto;
  background: 0 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fs14 {font-size: 14px;}
.fs18 {font-size: 18px;}
.w-100 {width: 100%;}
.margin-btm-5 {margin-bottom:5px;}
.bold {font-weight: bold;}
.fw-normal {font-weight: normal;}
.margin-right-5 {margin-right: 5px;}

.disclaimer {    
  width: 100%;
  min-height: 75px;
  background-color: #F9F9F9;
  border: 1px solid rgba(0, 0, 0, .3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0px 0px;

  
  .disclaimer-content{
    padding: 10px;
  }
}

.disclaimer.extra-margin {
  margin: 10px 0 10px 10px;
  width: calc(100% - 20px);
}

.legend-buttons-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px 0 0px 0;
}

#root, body, html {
  height: 100%;
}