span.date {
    font-weight: bold;
}
table {
    display: block;
    margin: 20px auto;
}
th, td {
    border: 1px solid #b2b2b2;
    padding: 10px;
}
.res-requirement-chart {
    width: 100%;
}
.strRequirement {
    margin-left: 50%;
    display: none; 
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    text-align: right;
}